import request from './request'
 export default {
    getWxUserInfo(params) {
        return request.post('api/auth/register', params)
    },
    addFeedBack(params) {//添加留言
        return request.post('api/api/v1/feedback/add', params)
    },
    getBarCode(params) {//识别二维码
        return request.post('api/api/v1/barCode/inquiry', params)
    },
    getRecordList(params){
        return request.post('api/api/v1/record/findAll', params)
    },
    updateAddress(params){
        return request.post('api/api/v1/barCode/updateAddress', params)
    },
    /**
     * notes redPack/pocketMoney
     * @param {*} params 
     */
    getPrize(params){//领取奖金 
        return request.post('api/api/v1/pay/prize', params)
    }
}