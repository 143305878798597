import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from '@/api'
import 'lib-flexible'
import '@/assets/css/reset.scss'
import '@/assets/css/border1px.scss'
import '@/utils/rem'
import Vant from "vant"
import 'vant/lib/index.css'
import {Toast} from "vant"
// import wx from "weixin-js-sdk"

Toast.setDefaultOptions({ duration: 2000 });
Vue.prototype.$toast = Toast
Vue.prototype.$api = Api
// Vue.prototype.$wx = wx
Vue.use(Vant)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
