import Vue from "vue";
import Vuex from "vuex";
import { getOpenId } from '../utils/auth';

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        hidShow: true,
        openId: getOpenId(),
        productId: '',
        userInfo: null
    },
    mutations: {
        SET_OPENID: (state, openId) => {
            state.openId = openId
        },
        SET_HIDSHOW: (state, hidShow) => {
            state.hidShow = hidShow
        },
        SET_PRODUCTID: (state, productId) => {
            state.productId = productId
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
        }
    },
    actions: {
        setOpenId({ commit }, openId) {
            commit('SET_OPENID', openId)
        },
        setHidShow({ commit }, hidShow) {
            commit('SET_HIDSHOW', hidShow)
        },
        setUserInfo({ commit }, userInfo) {
            commit('SET_USERINFO', userInfo)
        },
    },
    getters: {
        openId: state => state.openId,
        hidShow: state => state.hidShow,
        userInfo: state =>state.userInfo
    },
    modules: {
    }
})