import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Index.vue'),
        meta: { title: '首页', keepAlive: false }
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/views/Auth.vue'),
        meta: { title: '登录授权', keepAlive: false }
    },
    {
        path: '/main',
        name: 'Main',
        component: () => import('@/views/Main.vue'),
        meta: { title: '中心页面', keepAlive: false },
        children: [
            { name: 'About', path: 'about', component: () => import('@/views/About.vue') },
            { name: 'Center', path: 'center', component: () => import('@/views/Center.vue') },
            { name: 'Message', path: 'message', component: () => import('@/views/Message.vue') },
        ]
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue'),
        meta: { title: '产品介绍', keepAlive: false }
    },
    {
        path: '/center',
        name: 'Center',
        component: () => import('@/views/Center.vue'),
        meta: { title: '个人中心', keepAlive: false }
    },
    {
        path: '/message',
        name: 'Message',
        component: () => import('@/views/Message.vue'),
        meta: { title: '留言', keepAlive: false }
    },
    { path: '/error',name: 'error', component: () => import('@/views/error.vue') }

]


const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: () => ({ y: 0 })
})

router.beforeEach((to,from,next) => {
    let fullPath = to.fullPath
    if (to.name === 'error') {
        next()
        return
    }
    // console.log(to)
    if (!/micromessenger/i.test(navigator.userAgent)) {
        if (to.name === 'error') {
            next()
            return
        } else {
            next('/error')
        }
        // this.$toast.fail(`请在微信中打开使用！`)
        return
    }
    if (to.name === 'Auth') {
        next()
        return
    }
    let openId = localStorage.getItem('openId');
    // console.log('222')
    if (!openId) {
        //保存当前路由地址，授权后还会跳到此地址
        sessionStorage.setItem('wxRedirectUrl', fullPath)
        //请求微信授权,并跳转到 /auth 路由
        let appId = 'wxbd98637ade03cd01'
        // let redirectUrl = ''
        let redirectUrl = encodeURIComponent('https://prize.binglangwang.com/auth')
        // let redirectUrl = encodeURIComponent('https://syj.awayroadtrip.com/auth')
        //判断是否为正式环境
        if (window.location.origin.indexOf('https://prize.binglangwang.com') !== -1) {
            // appId = 'wxbd98637ade03cd01'
            redirectUrl = encodeURIComponent('https://prize.binglangwang.com/auth')
        } else {
            redirectUrl = encodeURIComponent('https://prize.binglangwang.com/auth')
            // redirectUrl = encodeURIComponent('https://syj.awayroadtrip.com/auth')
        }
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1`
    } else {
        next()
    }

})

export default router
