import axios from 'axios'

// create an axios instance
const service = axios.create({
  // baseURL: "https://prize.binglangwang.com", // api的base_url
  baseURL: process.env.VUE_APP_API, // api的base_url
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'accessToken': '',
    'openId': '',
    'deviceType': 'wechat'
  },
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (localStorage.getItem('token')) {
      config.headers['accessToken'] = localStorage.getItem('token')
    }
    return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  response => response,
  error => {
    let status = error.response.status;
    if(status === 401 || status === 403){
      localStorage.removeItem('token')
      localStorage.removeItem('openId')
      location.reload()
    }

    return Promise.reject(error)
  })

const ajaxMethod = ['get', 'post', 'delete', 'put'];
const api = {};
ajaxMethod.forEach((method) => {
  api[method] = function fun(uri, data, config) {
    return new Promise((resolve) => {
      service[method](uri, data, config).then((response) => {
        resolve(response);
      });
    });
  };
});
export default api
