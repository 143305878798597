
const OpenidKey = 'openId';
const TokenKey = 'token';
export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function getOpenId() {
    return localStorage.getItem(OpenidKey)
}

export function setOpenId(openid) {
    return localStorage.setItem(OpenidKey, openid)
}

export function removeOpenid() {
    return localStorage.removeItem(OpenidKey)
}